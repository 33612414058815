import { Component, ReactNode } from 'react';
import toggleDown from '../../icons/circle_chev_down.svg';

export class Expander extends Component<
    { toggleText: string | ReactNode; contents: ReactNode },
    { expanded: boolean }
> {
    constructor(props: any) {
        super(props);

        this.state = {
            expanded: false,
        };

        this.toggleExpander = this.toggleExpander.bind(this);
    }

    toggleExpander(event: any) {
        event.preventDefault();

        const expanded = this.state.expanded;
        this.setState({ expanded: !expanded });
    }

    render(): ReactNode {
        return (
            <div className="expander">
                <div
                    onClick={this.toggleExpander}
                    className={
                        this.state.expanded
                            ? 'expanded expander-toggle'
                            : 'expander-toggle'
                    }
                >
                    <span>{this.props.toggleText}</span>
                    <img
                        alt="toggle down"
                        src={toggleDown}
                        className={this.state.expanded ? 'flipped-img' : ''}
                    />
                </div>
                <div
                    className={
                        this.state.expanded
                            ? 'expanded-content'
                            : 'unexpanded-state expanded-content'
                    }
                >
                    {this.props.contents}
                </div>
            </div>
        );
    }
}
