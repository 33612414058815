import { ErrorOutline, WarningOutlined } from '@mui/icons-material';
import { Component, ReactNode, useContext, useState } from 'react';
import { BannerMenu } from '../components/BannerMenu';
import { LoadingSpinner } from '../components/mobile/LoadingSpinner';
import {
    FormButton,
    FormTextInput,
    FormTextLabel,
} from '../components/shared/FormComponents';
import {
    CallDynamoForParty,
    DynamoResponse,
    Person,
} from '../function/rsvp/GetParty';
import { UpdateParty } from '../function/rsvp/UpdateParty';
import { MobileContext } from '../context/deviceContext';

enum RSVPState {
    Query,
    Update,
    Confirmation,
}

interface StateContext {
    empty: boolean;
    enabled: boolean;
    errored: boolean;
    success?: boolean;
}

export function RSVP(): JSX.Element {
    const isMobile = useContext(MobileContext);
    const [rsvpState, setRsvpState] = useState<RSVPState>(RSVPState.Query);
    const [queryState, setQueryState] = useState<StateContext>({
        empty: false,
        enabled: true,
        errored: false,
    });
    const [updateState, setUpdateState] = useState<StateContext>({
        empty: false,
        enabled: true,
        errored: false,
        success: false,
    });
    const [showSpinner, setShowSpinner] = useState(false);
    const [response, setResponse] = useState<DynamoResponse>({ party: [] });

    const handleQuery = (event: any): void => {
        event.preventDefault();

        setQueryState({
            empty: false,
            enabled: false,
            errored: false,
        });

        const formData = new FormData(event.target);
        const name = (formData.get('name') as string).trim();

        if (name.length) {
            setShowSpinner(true);

            let queryStateUpdate = queryState;
            let rsvpStateUpdate = rsvpState;

            CallDynamoForParty(name)
                .then((data) => {
                    if (data.party.length > 0) {
                        setResponse(data);
                        rsvpStateUpdate = RSVPState.Update;
                    } else {
                        setResponse(data);
                        queryStateUpdate.empty = true;
                    }
                })
                .catch(() => {
                    queryStateUpdate.errored = true;
                })
                .finally(() => {
                    queryStateUpdate.enabled = true;

                    setQueryState(queryStateUpdate);
                    setRsvpState(rsvpStateUpdate);
                    setShowSpinner(false);
                });
        } else {
            setQueryState({ ...queryState, enabled: true, errored: true });
        }
    };

    const handleUpdate = (event: any): void => {
        event.preventDefault();

        setShowSpinner(true);
        setUpdateState({ ...updateState, enabled: false });

        let updateStateUpdate = updateState;
        updateStateUpdate.enabled = false;

        const formData = new FormData(event.target);

        UpdateParty(response.party, formData)
            .then((isSuccess) => {
                updateStateUpdate.success = isSuccess;
                if (isSuccess) {
                    setRsvpState(RSVPState.Confirmation);
                }
            })
            .catch((isSuccess) => {
                updateStateUpdate.success = isSuccess;
            })
            .finally(() => {
                updateStateUpdate.enabled = true;

                setUpdateState(updateStateUpdate);
                setShowSpinner(false);
            });
    };

    const getMobileRender = (): ReactNode => {
        return getDesktopRender();
    };

    const getDesktopRender = (): ReactNode => {
        return (
            <div>
                {rsvpState === RSVPState.Query && (
                    <div id="rsvp-query">
                        <form id="rsvp-query-form" onSubmit={handleQuery}>
                            <h3 className="section-header">
                                Find your invitation
                            </h3>
                            <p>
                                Please let us know if you'll be attending by
                                August 16, 2024.
                            </p>
                            <p>
                                If you have any trouble finding yours, please{' '}
                                <a href="/contact">reach out to us!</a>
                            </p>
                            {!queryState.enabled && (
                                <fieldset
                                    className="hidden"
                                    disabled={true}
                                ></fieldset>
                            )}
                            <FormTextLabel
                                text="Full name:"
                                for="query-form-name"
                            />
                            <FormTextInput
                                name="name"
                                placeholder="Your first and last name"
                            />
                            {queryState.enabled && <FormButton text="Search" />}
                        </form>
                        {queryState.errored && (
                            <div className="error">
                                <ErrorOutline
                                    style={{ verticalAlign: 'middle' }}
                                />{' '}
                                <span>
                                    Something went wrong there! Try that request
                                    again making sure to enter your first and
                                    last name.
                                </span>
                            </div>
                        )}
                        {queryState.empty && (
                            <div>
                                <span>
                                    Looks like we couldn't find a party for this
                                    name.
                                </span>
                            </div>
                        )}
                    </div>
                )}
                {rsvpState === RSVPState.Update && (
                    <div id="rsvp-update">
                        <form id="rsvp-update-form" onSubmit={handleUpdate}>
                            <h3 className="section-header">
                                Update your party
                            </h3>
                            <p className="section-p">
                                Here's your party's current status. Feel free to
                                change it and click update to update our
                                records.
                                {<br />}
                                If you're not sure yet, selecting neither
                                "accept" nor "decline" is fine, too.
                            </p>
                            {updateState.errored && (
                                <div className="alert">
                                    <p>
                                        <WarningOutlined
                                            style={{
                                                verticalAlign: 'middle',
                                            }}
                                        />{' '}
                                        Uh oh! Something went wrong submitting
                                        your update. Please try again. If the
                                        issue continues, please reach out to us.
                                    </p>
                                </div>
                            )}
                            {response.party.map((person, i) => (
                                <div key={i} className="party-person">
                                    <RSVPToggle person={person} />
                                    <textarea
                                        defaultValue={person.notes}
                                        id={'notes-' + i}
                                        className="notes"
                                        placeholder="Anything we should know? (e.g., dietary restrictions, food allergies, etc.)"
                                        name={person.name + '|notes'}
                                    />
                                </div>
                            ))}
                            <br />
                            {updateState.enabled && (
                                <FormButton text="Update" />
                            )}
                        </form>
                    </div>
                )}
                {rsvpState === RSVPState.Confirmation && (
                    <div id="rsvp-confirmation">
                        <h3>We got your response!</h3>
                    </div>
                )}
                <LoadingSpinner hidden={!showSpinner} id="rsvp-spinner" />
            </div>
        );
    };

    return (
        <div id="rsvp-page">
            <BannerMenu />
            {isMobile ? getMobileRender() : getDesktopRender()}
        </div>
    );
}

class RSVPToggle extends Component<
    { person: Person },
    { response: 'YES' | 'NO' | 'UNKNOWN' }
> {
    constructor(props: any) {
        super(props);

        this.state = {
            response: this.props.person.response || 'UNKNOWN',
        };

        this.onResponseChange = this.onResponseChange.bind(this);
    }

    onResponseChange(event: any) {
        event.preventDefault();

        const selectedResponse =
            event.target.classList[1] === 'no' ? 'NO' : 'YES';
        if (selectedResponse === this.state.response) {
            this.setState({ response: 'UNKNOWN' });
        } else {
            switch (selectedResponse) {
                case 'YES':
                    this.setState({ response: 'YES' });
                    break;
                case 'NO':
                    this.setState({ response: 'NO' });
                    break;
                default:
                    this.setState({ response: 'UNKNOWN' });
                    break;
            }
        }
    }

    render(): ReactNode {
        return (
            <div className="rsvp-toggle">
                <input
                    type="hidden"
                    name={this.props.person.name + '|response'}
                    value={this.state.response}
                />

                <p className="toggle-name">{this.props.person.fullname}</p>
                <div className="toggle-options">
                    <span
                        onClick={this.onResponseChange}
                        className={
                            this.state.response === 'YES'
                                ? 'selected yes'
                                : 'unselected yes'
                        }
                    >
                        Accept
                    </span>
                    <span
                        onClick={this.onResponseChange}
                        className={
                            this.state.response === 'NO'
                                ? 'selected no'
                                : 'unselected no'
                        }
                    >
                        Decline
                    </span>
                </div>
            </div>
        );
    }
}
