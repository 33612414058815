import { Buffer } from 'buffer';
import { InvokeCommand, LambdaClient } from '@aws-sdk/client-lambda';
import { Person } from './GetParty';
import { getLambdaClient } from '../awsCommon';

export async function UpdateParty(
    prevParty: Person[],
    formData: FormData
): Promise<boolean> {
    const existingParty = new Set(prevParty);
    const updatedParty: Map<string, Person> = new Map();

    formData.forEach((value, key) => {
        const keyTokens = key.split('|');
        const name = keyTokens[0];
        const attribute = keyTokens[1];

        let person = updatedParty.get(name);
        if (person === undefined) {
            person = {
                party: 'string',
                name: 'string',
                fullname: 'string',
                response: 'YES',
                notes: 'string',
            };
            for (let oldPerson of existingParty) {
                if (oldPerson.name === name) {
                    person = {
                        party: oldPerson.party,
                        name: oldPerson.name,
                        fullname: oldPerson.fullname,
                        response: oldPerson.response,
                        notes: oldPerson.notes,
                    };
                    break;
                } else {
                    // ERROR
                }
            }
        }

        if (attribute === 'response') {
            person.response =
                value === 'YES' ? value : value === 'NO' ? value : 'UNKNOWN';
        } else if (attribute === 'notes') {
            person.notes = value.toString();
        }

        updatedParty.set(name, person);
    });

    let people: Person[] = [];
    updatedParty.forEach((person) => {
        people.push(person);
    });

    return await invokeFunction(people);
}

async function invokeFunction(people: Person[]): Promise<boolean> {
    const client = getLambdaClient();

    const command = new InvokeCommand({
        FunctionName: 'RSVPUpdater',
        Payload: JSON.stringify({
            body: JSON.stringify(people),
        }),
    });

    try {
        const { Payload } = await client.send(command);
        if (Payload) {
            const response = JSON.parse(Buffer.from(Payload).toString());
            return response.statusCode === 200;
        }
    } catch (err) {
        console.log(err);
    }
    return false;
}
