import { BannerMenu } from '../components/BannerMenu';
import { ContactUs as ContactUsComponent } from '../components/desktop/ContactUs';

export const ContactUs = () => {
    return (
        <div id="contact-us-page">
            <BannerMenu />
            <ContactUsComponent />
        </div>
    );
};
