'use-strict';
import { Buffer } from 'buffer';
import { InvokeCommand, LambdaClient } from '@aws-sdk/client-lambda';
import { getLambdaClient } from '../awsCommon';

export interface DynamoResponse {
    party: Person[];
}

export interface Person {
    party: string;
    name: string;
    fullname: string;
    response?: 'YES' | 'NO' | 'UNKNOWN';
    notes?: string;
}

function handleSuccess(payload: any): DynamoResponse {
    let response: DynamoResponse = { party: [] };
    if (payload.items) {
        response = {
            party: payload.items,
        };
    }
    return response;
}

export async function CallDynamoForParty(
    name: string
): Promise<DynamoResponse> {
    const client = getLambdaClient();

    name = name.replace(/ +(?= )/g, '');

    const command = new InvokeCommand({
        FunctionName: 'RSVPHandler',
        Payload: JSON.stringify({
            headers: { origin: 'https://www.erinandethan.com' },
            queryStringParameters: {
                name: name,
            },
        }),
    });

    try {
        const { Payload } = await client.send(command);
        if (Payload) {
            const response = JSON.parse(Buffer.from(Payload).toString());
            return handleSuccess(JSON.parse(response.body));
        } else {
            return { party: [] };
        }
    } catch (err) {
        return { party: [] };
    }
}
