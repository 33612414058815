import { Component, ReactNode } from 'react';
import { BannerMenu } from '../../components/BannerMenu';

export class FAQ extends Component {
    render(): ReactNode {
        return (
            <div id="faq-page">
                <BannerMenu />
                <div>
                    <h1 className="section-header">
                        Frequently Asked Questions
                    </h1>
                    <h4>
                        We're slowly building this list so if you don't see an
                        answer to your question, reach out to us!
                    </h4>
                    <p className="faq-body">
                        <h3>When is the RSVP deadline?</h3>
                        <p>
                            Please let us know if you will or will not be able
                            to make it by{' '}
                            <span className="bold">August 16</span>.
                        </p>
                        <h3>How do I RSVP?</h3>
                        <p>
                            Visit <a href="/rsvp">our RSVP page</a> and enter
                            your first and last name. It should find your
                            "party" of people. If you are unable to do this or
                            have trouble finding your party, feel free to reach
                            out to us on{' '}
                            <a href="/contact">our Contact Us page</a> or by
                            call or text.
                        </p>
                        <h3>What time should I arrive for the ceremony?</h3>
                        <p>
                            Please arrive at 3 PM. We plan to begin the ceremony
                            by 3:30 PM.
                        </p>
                        <h3>Is everything in the same location?</h3>
                        <p>
                            Yes -- both our ceremony and reception will be held
                            at the Silverthorne Pavilion.
                        </p>
                        <h3>What weather should I expect?</h3>
                        <p>
                            Expect daytimes to be comfortable with temperatues
                            dropping after dark. Silverthorne in September is
                            typically sunny with an average daily high of 66ºF
                            and an average daily low of 32ºF. Overall, the
                            weather in the mountains can be unpredicable. We
                            encourage everyone to bring options and layers for
                            their visit.
                        </p>
                        <h3>
                            What's the elevation in Silverthorne? Anything I
                            should know?
                        </h3>
                        <p>
                            Silverthorne, CO is at approximately 8,800 feet
                            above sea level. To avoid altitude sickness, please
                            hydrate excessively during the week leading up the
                            event and continue to drink plenty of fluids
                            throughout your trip! Alcohol's effects tend to be
                            more potent at higher elevations so be mindful of
                            consumption during your visit.
                        </p>
                        <h3>What should I wear?</h3>
                        <p>
                            The dress code is dressy casual. We ask you dress
                            cleanly and comfortably for the weather and for
                            dancing. There will be a brief outdoor ceremony with
                            the rest of the event taking place indoors. All
                            walkways in and around the venue are paved. Please
                            avoid attire such as jeans and sneakers.
                        </p>
                        <h3>What about children?</h3>
                        <p>
                            We don't anticipate many children due to travel, but
                            they are welcome to attend.{' '}
                            <span className="bold">
                                There will not be childcare services
                            </span>{' '}
                            at the venue and the entertainment will be
                            adult-focused. Please reach out to us if you wish to
                            bring young children or have any other concerns.
                        </p>
                        <h3>What about a plus one?</h3>
                        <p>
                            If your invitation does not include a plus one, we
                            are not expecting you to bring another person. Feel
                            free to reach out to us with any concerns.
                        </p>
                        <h3>Do I need to send or bring a gift?</h3>
                        <p>
                            No -- we do not expect gifts from any of our guests.
                            We understand that most of you are traveling and all
                            of you are taking time to come celebrate with us and
                            we appreciate that so so much! Some of you have
                            asked about gifts, though, so we have provided a
                            wish-list of ours that is linked on our{' '}
                            <a href="/registry">Regsitry page</a>.
                        </p>
                        <h3>What kind of food are you serving?</h3>
                        <p>
                            We will be serving hors d'oeuvres during cocktail
                            hour followed by a buffet style dinner with a
                            selection of meat, vegetarian, and gluten-free
                            options. There will be a dairy and gluten free
                            dessert option, as well. If you have any dietary
                            restrictions or allergies, please note them on your
                            RSVP and we will do our best to accommodate you.
                            We'll have more details to come on the menu!
                        </p>
                        <h3>Can I take photos during the event?</h3>
                        <p>
                            We will be having an unplugged ceremony so please
                            keep your phones and cameras tucked away during
                            that. You are welcome to take pictures throughout
                            the rest of the event, though. Please remember to
                            prioritize our photographer's camera throughout the
                            event.
                        </p>
                        <h3>Can I share photos from the wedding online?</h3>
                        <p>
                            Sure! Feel free to post any photos you take during
                            the event.
                        </p>
                        <h3>How do I get to and from the venue?</h3>
                        <p>
                            If you're planning to stay the night in
                            Silverthorne, we encourage you to find lodging at
                            one of the two hotels within walking distance of our
                            venue, the Silverthorne Pavilion. If you plan to
                            drive to and from the wedding, there's plenty of
                            free parking available across the footbridge that
                            spans Blue River from the venue adjacent to the
                            venue. Please see our{' '}
                            <a href="/travel">Travel and Stay page</a> for a lot
                            more information on traveling and our{' '}
                            <a href="/event">Event and Venue page</a> for a map
                            of the key locations around the venue.
                        </p>
                        <h3>Can I use rideshare apps in Silverthorne?</h3>
                        <p>
                            While Lyft and Uber are available in Silverthorne,
                            they're not very reliable, especially outside 9am to
                            5pm. If you plan to explore Silverthorne and its
                            surrounding, we highly recommend trying the{' '}
                            <a href="https://www.summitcountyco.gov/services/transit_summit_stage/index.php">
                                Summit Stage bus service
                            </a>
                            . It's totally free and has stops around
                            Silverthorne, Dillon, Frisco, and Breckenridge.
                        </p>
                        <h3>
                            Are there any other wedding-related activities I
                            should be aware of?
                        </h3>
                        <p>
                            We are currently in the process of reserving the bar
                            attached to the Hotel Indigo for the evening after
                            the reception. Anyone that wishes to continue to
                            celebrate after the reception has concluded is
                            welcome to join us. We'll have more details on this
                            soon!
                        </p>
                        <p>
                            If you are interested in exploring Silverthorne and
                            its surrounding, check out the "Things to do in
                            Silverthorne" section on our{' '}
                            <a href="/travel">Travel and Stay page</a>.
                        </p>
                    </p>
                </div>
            </div>
        );
    }
}
