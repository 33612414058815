import { InvokeCommand } from '@aws-sdk/client-lambda';
import { Buffer } from 'buffer';
import { useContext, useEffect, useState } from 'react';
import { Cell, Pie, PieChart, ResponsiveContainer, Tooltip } from 'recharts';
import { Expander } from '../../components/desktop/Expander';
import { LoadingSpinner } from '../../components/mobile/LoadingSpinner';
import {
    FormSecondaryButton,
    FormTextInput,
    FormTextLabel,
} from '../../components/shared/FormComponents';
import { AuthenticatedContext } from '../../context/authenticationContext';
import { getLambdaClient } from '../../function/awsCommon';
import {
    authenticateUser,
    confirmUser,
    isAuthenticated,
    logoutUser,
} from '../../function/cognitoAuth';
import { Person } from '../../function/rsvp/GetParty';

export const DashboardPage = (): JSX.Element => {
    const [authenticated, setAuthenticated] = useState(false);

    useEffect(() => {
        isAuthenticated().then((signedIn) => {
            setAuthenticated(signedIn);
        });
    }, []);

    return (
        <AuthenticatedContext.Provider
            value={{ authenticated, setAuthenticated }}
        >
            {authenticated ? <DashboardContents /> : <SignIn />}
        </AuthenticatedContext.Provider>
    );
};

const SignIn = (): JSX.Element => {
    const authenticatedContext = useContext(AuthenticatedContext);
    const [showSpinner, setShowSpinner] = useState(false);
    const [shouldConfirm, setShouldConfirm] = useState(false);
    const [newPassRejectMessage, setNewPassRejectMessage] = useState('');

    const handleSignIn = (event: any) => {
        event.preventDefault();
        setShowSpinner(true);

        const formData = new FormData(event.target);
        const username = formData.get('username') as string;
        const password = formData.get('password') as string;

        authenticateUser(username, password).then(({ success, confirm }) => {
            setShowSpinner(false);
            if (confirm) {
                setShouldConfirm(true);
            } else {
                authenticatedContext.setAuthenticated(success);
            }
        });
    };

    const handleNewPassword = (event: any) => {
        event.preventDefault();
        setShowSpinner(true);
        setNewPassRejectMessage('');

        const formData = new FormData(event.target);
        const newPassword = formData.get('new-password') as string;

        const numberRegex = new RegExp('\\d+');
        const specialCharacter = new RegExp('[!@#$%^&*)(+=._-]+');
        const uppercase = new RegExp('[A-Z]+');
        const lowercase = new RegExp('[a-z]+');

        if (newPassword.length < 8) {
            setNewPassRejectMessage(
                'Password must be at least 8 characters long.'
            );
            setShowSpinner(false);
        } else if (!numberRegex.test(newPassword)) {
            setNewPassRejectMessage(
                'Password must contain at least one number character.'
            );
            setShowSpinner(false);
        } else if (!specialCharacter.test(newPassword)) {
            setNewPassRejectMessage(
                'Password must contain at least one special character.'
            );
            setShowSpinner(false);
        } else if (!uppercase.test(newPassword)) {
            setNewPassRejectMessage(
                'Password must contain at least one uppercase character.'
            );
            setShowSpinner(false);
        } else if (!lowercase.test(newPassword)) {
            setNewPassRejectMessage(
                'Password must contain at least one lowercase character.'
            );
            setShowSpinner(false);
        } else {
            confirmUser(newPassword).then(({ success }) => {
                setShowSpinner(false);
                authenticatedContext.setAuthenticated(success);
            });
        }
    };

    return (
        <div>
            <form onSubmit={handleSignIn}>
                <label htmlFor="username">Username:</label>
                <input type="text" id="username" name="username" />
                <label htmlFor="password">Password:</label>
                <input type="password" id="password" name="password" />
                <input type="submit" value="Submit" />
            </form>
            {shouldConfirm && (
                <form onSubmit={handleNewPassword}>
                    <p>Looks like we need to update your password...</p>
                    <label htmlFor="new-password">New password: </label>
                    <input
                        type="password"
                        name="new-password"
                        id="new-password"
                    />
                    <input type="submit" value="Submit" />
                </form>
            )}
            {newPassRejectMessage}
            <LoadingSpinner id="sign-in-spinner" hidden={!showSpinner} />
        </div>
    );
};

const DashboardContents = (): JSX.Element => {
    const [party, setParty] = useState<Person[]>([]);

    useEffect(() => {
        getData().then((partyFromDb) => setParty(partyFromDb));
    }, []);

    const getData = async (): Promise<Person[]> => {
        const client = getLambdaClient();
        const command = new InvokeCommand({
            FunctionName: 'DashboardScan',
        });

        return client.send(command).then((res: any) => {
            const payload = JSON.parse(Buffer.from(res.Payload).toString());
            const body = JSON.parse(payload.body);

            return body.map((item: any): Person => {
                return {
                    party: item.party.S,
                    fullname: item.fullname.S,
                    name: item.name.S,
                    response: item.response ? item.response.S : 'UNKNOWN',
                    notes: item.notes ? item.notes.S : '',
                };
            });
        });
    };

    const getSummary = (): any[] => {
        const reducedData = {
            yes: 0,
            no: 0,
            unknown: 0,
        };

        party.forEach((person) => {
            if (person.response === 'YES') {
                reducedData.yes = reducedData.yes + 1;
            } else if (person.response === 'NO') {
                reducedData.no = reducedData.no + 1;
            } else {
                reducedData.unknown = reducedData.unknown + 1;
            }
        });

        return [
            { name: 'yes', value: reducedData.yes, color: '#82C09A' },
            { name: 'no', value: reducedData.no, color: '#FF6B6C' },
            { name: 'unknown', value: reducedData.unknown, color: '#FFC145' },
        ];
    };

    const getTable = (): any[] => {
        const tableContents: any = [];

        party.forEach((person) => {
            tableContents.push({
                party: person.party,
                name: person.fullname,
                response: person.response,
                notes: person.notes,
            });
        });

        return tableContents;
    };

    const exportAsCsv = () => {
        function downloadBlob(
            content: string,
            filename: string,
            contentType: string
        ) {
            // Create a blob
            var blob = new Blob([content], { type: contentType });
            var url = URL.createObjectURL(blob);

            // Create a link to download it
            var pom = document.createElement('a');
            pom.href = url;
            pom.setAttribute('download', filename);
            pom.click();
        }

        const tableContents = getTable();
        let fileContents = ',Party,Name,Response,Notes\n';

        tableContents.forEach((row, i) => {
            fileContents += `${i},${row.party},${row.name},${row.response},${row.notes}\n`;
        });

        downloadBlob(fileContents, 'myFile.csv', 'text/csv;charset=utf-8;');
    };

    const handleLogOut = (event: any) => {
        event.preventDefault();
        logoutUser().then(() => {
            window.location.replace('/');
        });
    };

    const data1 = getSummary();

    return (
        <div id="dashboard-page">
            <h3 className="section-header">RSVP Dashboard</h3>
            <div className="dashboard-nav">
                <span>
                    <a href="/">Return to website</a>
                </span>
                <span>
                    <a href="#" onClick={handleLogOut}>
                        Log out and return to website
                    </a>
                </span>
            </div>
            <div className="quick-summary">
                <div className="left-actions">
                    <ResponsiveContainer width={400} height={250}>
                        <PieChart width={400} height={250}>
                            <Pie
                                data={data1}
                                cx="50%"
                                cy="50%"
                                innerRadius={60}
                                outerRadius={80}
                                fill="#785a53"
                                dataKey="value"
                                label={(e) => {
                                    return e.name;
                                }}
                            >
                                {data1.map((e: any, i: any) => (
                                    <Cell key={i} fill={e.color} />
                                ))}
                            </Pie>
                            <Tooltip />
                        </PieChart>
                    </ResponsiveContainer>
                </div>
                <div className="middle-actions">
                    <div className="search-action action">
                        <form>
                            <div>
                                <FormTextLabel text="Full name" for="search" />
                                <FormTextInput name="search" />
                            </div>
                            <div className="search-button-container">
                                <FormSecondaryButton text="Search" />
                            </div>
                        </form>
                    </div>
                    <div className="add-action action">
                        <span className="action-header">Add a guest</span>
                        <form>
                            <FormTextLabel text="Full name" for="full-name" />
                            <FormTextInput name="full-name" />
                            <FormTextLabel text="Party" for="party" />
                            <FormTextInput name="party" />
                            <FormSecondaryButton text="Add" />
                        </form>
                    </div>
                </div>
                <div className="right-actions">
                    <h4>Quick actions</h4>
                    <button onClick={exportAsCsv} className="my-form-button">
                        Download as CSV
                    </button>
                </div>
            </div>
            <div className="responses-table">
                <h3 className="section-header">Current Responses</h3>
                <table>
                    <thead>
                        <tr>
                            <th></th>
                            <th>Party</th>
                            <th>Name</th>
                            <th>Response</th>
                            <th>Notes</th>
                        </tr>
                    </thead>
                    <tbody>
                        {getTable().map((e, i) => {
                            return (
                                <tr key={i}>
                                    <td>{i + 1}</td>
                                    <td>{e.party}</td>
                                    <td>{e.name}</td>
                                    <td>{e.response}</td>
                                    <td>
                                        {e.notes.length ? (
                                            <Expander
                                                toggleText="Show notes"
                                                contents={e.notes}
                                            />
                                        ) : (
                                            e.notes
                                        )}
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    );
};
