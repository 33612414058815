import './FormComponents.scss';

export const FormButton = (props: { text?: string }): JSX.Element => {
    return (
        <button type="submit" className="my-form-button">
            {props.text || 'Send'}
        </button>
    );
};

export const FormSecondaryButton = (props: { text?: string }): JSX.Element => {
    return (
        <button type="submit" className="my-form-secondary-button">
            {props.text || 'Send'}
        </button>
    );
};

export const FormTextInput = (props: {
    name: string;
    id?: string;
    placeholder?: string;
}): JSX.Element => {
    return (
        <div className="text-input-container">
            <input
                className="text-input"
                type="text"
                name={props.name}
                id={props.id || props.name}
                placeholder={props.placeholder || ''}
            />
        </div>
    );
};

export const FormTextLabel = (props: {
    text: string;
    for: string;
}): JSX.Element => {
    return (
        <div className="form-text-label">
            <label htmlFor={props.for}>{props.text}</label>
        </div>
    );
};
