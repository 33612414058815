import { useContext } from 'react';
import { MobileContext } from '../context/deviceContext';
import emblem from '../photos/emblem.png';
import { SideMenu } from './mobile/SideMenu';

export function BannerMenu(): JSX.Element {
    const isMobile = useContext(MobileContext);

    return isMobile ? mobile() : desktop();
}

function desktop(): JSX.Element {
    return (
        <div className="banner-menu">
            <div id="emblem-container">
                <a href="/">
                    <img src={emblem} alt="emblem" />
                </a>
            </div>
            <div className="header-text-container">
                <h1>Erin and Ethan</h1>
            </div>
            <div id="date-container">
                <span>
                    3:00 PM &bull; SEPTEMBER 27, 2024 &bull; SILVERTHORNE, CO
                </span>
            </div>
            <div id="nav">
                <ol>
                    <li
                        className={
                            window.location.pathname === '/' ? 'this' : ''
                        }
                    >
                        <a href="/">HOME</a>
                    </li>
                    <li
                        className={
                            window.location.pathname === '/photos' ? 'this' : ''
                        }
                    >
                        <a href="/photos">PHOTOS</a>
                    </li>
                    <li
                        className={
                            window.location.pathname === '/travel' ? 'this' : ''
                        }
                    >
                        <a href="/travel">TRAVEL & STAY</a>
                    </li>
                    <li
                        className={
                            window.location.pathname === '/event' ? 'this' : ''
                        }
                    >
                        <a href="/event">EVENT & VENUE</a>
                    </li>
                    <li
                        className={
                            window.location.pathname === '/faq' ? 'this' : ''
                        }
                    >
                        <a href="/faq">FAQ</a>
                    </li>
                    <li
                        className={
                            window.location.pathname === '/registry'
                                ? 'this'
                                : ''
                        }
                    >
                        <a href="/registry">REGISTRY</a>
                    </li>
                    <li
                        className={
                            window.location.pathname === '/rsvp' ? 'this' : ''
                        }
                    >
                        <a href="/rsvp">RSVP</a>
                    </li>
                </ol>
            </div>
        </div>
    );
}

function mobile(): JSX.Element {
    return (
        <div className="banner-menu">
            <SideMenu />
            <div id="emblem-container">
                <a href="/">
                    <img src={emblem} alt="emblem" />
                </a>
            </div>
            <div className="header-text-container">
                <h1>
                    Erin <span className="minor">and</span> Ethan
                </h1>
            </div>
            <div id="date-container">
                <span>
                    3:00 PM &bull; SEPTEMBER 27, 2024{<br />}SILVERTHORNE, CO
                </span>
            </div>
        </div>
    );
}
