import { ErrorOutline, WarningOutlined } from '@mui/icons-material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { Component, ReactNode } from 'react';
import { FormButton, FormTextLabel } from '../shared/FormComponents';
import { FormTextInput } from '../shared/FormComponents';
import { callContactUs } from '../../function/contactUs';

export class ContactUs extends Component<
    {},
    {
        isFormEnabled: boolean;
        showSuccess: boolean;
        showError: boolean;
        invalidForm: boolean;
    }
> {
    constructor(props: any) {
        super(props);

        this.state = {
            isFormEnabled: true,
            showSuccess: false,
            showError: false,
            invalidForm: false,
        };

        this.onSubmit = this.onSubmit.bind(this);
        this.handleError = this.handleError.bind(this);
        this.handleSuccess = this.handleSuccess.bind(this);
    }

    onSubmit(event: any) {
        event.preventDefault();

        this.setState({
            isFormEnabled: false,
            showError: false,
            showSuccess: false,
            invalidForm: false,
        });

        const formData = new FormData(event.target);
        if (this.isValidForm(formData)) {
            // Convert to JSON
            let jsonFormData: any = {};
            formData.forEach((value, key) => {
                jsonFormData[key] = value;
            });

            callContactUs(jsonFormData)
                .then(this.handleSuccess)
                .catch(this.handleError)
                .finally(() => {
                    this.setState({ isFormEnabled: true });
                });
        } else {
            this.setState({ invalidForm: true });
        }
    }

    isValidForm(formData: FormData): boolean {
        return (
            formData.get('name') !== null &&
            (formData.get('name') as string).length > 0 &&
            formData.get('message') !== null &&
            (formData.get('message') as string).length > 0
        );
    }

    handleSuccess() {
        this.setState({ showSuccess: true });
        (document.getElementById('contact-us-form') as HTMLFormElement).reset();
    }

    handleError() {
        this.setState({ showError: true });
    }

    render(): ReactNode {
        return (
            <div id="contact-us">
                <h3 className="section-header">Contact us</h3>
                {/* <p style={{ margin: '0 4rem' }}>If you'd like to contact us</p> */}
                {this.state.showSuccess && (
                    <div className="success alert">
                        <CheckCircleOutlineIcon
                            style={{ verticalAlign: 'middle' }}
                        />{' '}
                        <span>We've received your message. Thanks!</span>
                    </div>
                )}
                {this.state.showError && (
                    <div className="error alert">
                        <ErrorOutline style={{ verticalAlign: 'middle' }} />{' '}
                        <span>
                            Uh oh! Something went wrong. Try sending that
                            message again.
                        </span>
                    </div>
                )}
                {this.state.invalidForm && (
                    <div className="warning alert">
                        <WarningOutlined style={{ verticalAlign: 'middle' }} />{' '}
                        <span>
                            Uh oh! Looks like you're either missing a name or a
                            message. Please fill in the required fields and try
                            sending your message again.
                        </span>
                    </div>
                )}
                <form onSubmit={this.onSubmit} id="contact-us-form">
                    {!this.state.isFormEnabled && (
                        <fieldset className="hidden" disabled={true}></fieldset>
                    )}
                    <FormTextLabel text="What's your name?" for="name-input" />
                    <FormTextInput
                        name="name"
                        id="name-input"
                        placeholder="Full name"
                    />
                    <FormTextLabel
                        text="How can we respond to you?"
                        for="response-via-input"
                    />
                    <FormTextInput
                        name="response-contact"
                        id="response-via-input"
                        placeholder="Phone or email"
                    />
                    <FormTextLabel
                        text="What's your message?"
                        for="message-input"
                    />
                    <div style={{ marginBottom: '1rem' }}>
                        <textarea name="message" id="message-input" />
                    </div>
                    <FormButton />
                </form>
            </div>
        );
    }
}
