import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Component, ReactNode } from 'react';

export class Expander extends Component<
    { toggleText: string; contents: ReactNode },
    { expanded: boolean }
> {
    constructor(props: any) {
        super(props);

        this.state = {
            expanded: false,
        };

        this.handleToggle = this.handleToggle.bind(this);
    }

    handleToggle(event: any) {
        event.preventDefault();

        this.setState({ expanded: !this.state.expanded });
    }

    render(): ReactNode {
        return (
            <div className="expander">
                <span className="toggle" onClick={this.handleToggle}>
                    {this.props.toggleText}
                    {this.state.expanded ? <ExpandLess /> : <ExpandMore />}
                </span>
                <div
                    className={
                        this.state.expanded ? 'expanded contents' : 'contents'
                    }
                >
                    {this.props.contents}
                </div>
            </div>
        );
    }
}
