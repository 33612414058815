import { Amplify } from 'aws-amplify';
import {
    SignInOutput,
    confirmSignIn,
    getCurrentUser,
    signIn,
    signOut,
} from 'aws-amplify/auth';

Amplify.configure({
    Auth: {
        Cognito: {
            userPoolId: 'us-east-2_FdIhNSxb5',
            userPoolClientId: '1j1vupcbv4b3gmjqrctmvuq75i',
        },
    },
});

export const isAuthenticated = async (): Promise<boolean> => {
    return new Promise(async (resolve) => {
        try {
            await getCurrentUser();
            return resolve(true);
        } catch (err) {
            return resolve(false);
        }
    });
};

export const authenticateUser = async (
    username: string,
    password: string
): Promise<{ success: boolean; confirm?: boolean; user?: SignInOutput }> => {
    return new Promise(async (resolve) => {
        try {
            const isSignedIn = await isAuthenticated().then(
                (alreadySignedIn) => {
                    return alreadySignedIn;
                }
            );
            if (isSignedIn) {
                return resolve({ success: true });
            } else {
                const user = await signIn({
                    username,
                    password,
                });
                if (user.nextStep.signInStep === 'DONE') {
                    return resolve({ success: isSignedIn });
                } else {
                    return resolve({ success: false, confirm: true });
                }
            }
        } catch (error) {
            console.log(error);
            resolve({ success: false });
        }
    });
};

export const confirmUser = async (
    newPassword: string
): Promise<{ success: boolean }> => {
    return new Promise(async (resolve) => {
        try {
            const { isSignedIn, nextStep } = await confirmSignIn({
                challengeResponse: newPassword,
            });
            if (isSignedIn) {
                return resolve({ success: true });
            } else {
                console.log(nextStep.signInStep);
                return resolve({ success: false });
            }
        } catch (err) {
            console.error(err);
            return resolve({ success: false });
        }
    });
};

export const logoutUser = async (): Promise<void> => {
    return new Promise(async (resolve) => {
        try {
            await signOut();
        } catch (err) {
            console.error(err);
        }
        resolve();
    });
};
