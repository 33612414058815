import { createContext } from 'react';

export interface AuthenticatedContextProps {
    authenticated: boolean;
    setAuthenticated: Function;
}

export const AuthenticatedContext = createContext<AuthenticatedContextProps>({
    authenticated: false,
    setAuthenticated: () => {},
});
