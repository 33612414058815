import { LambdaClient } from '@aws-sdk/client-lambda';

let client: LambdaClient;
const clientConfig = {
    region: 'us-east-2',
    credentials: {
        secretAccessKey: 'pHLrI1vwdLxaQtFT69XmQ8OOGgaLTrMUK6Mg3aGa',
        accessKeyId: 'AKIAV33IWUEPJH3IH2DG',
    },
};

export function getLambdaClient() {
    if (!client) {
        client = new LambdaClient(clientConfig);
    }

    return client;
}
