import { Component, ReactNode } from 'react';
import { BannerMenu } from '../../components/BannerMenu';
import { Expander } from '../../components/desktop/Expander';

class Travel extends Component {
    private getLodging(): ReactNode {
        const indigoMap = (
            <div id="indigo-map">
                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m26!1m12!1m3!1d1536.3947457882618!2d-106.0750477055983!3d39.63194342265526!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m11!3e2!4m5!1s0x876a5c864abb083d%3A0xf5914f5797d2b329!2sSilverthorne%20Pavilion%2C%20Blue%20River%20Parkway%2C%20Silverthorne%2C%20CO!3m2!1d39.6328722!2d-106.0736944!4m3!3m2!1d39.632071499999995!2d-106.0744362!5e0!3m2!1sen!2sus!4v1687708525480!5m2!1sen!2sus"
                    loading="eager" // TODO: test this
                    title="indigo-map"
                ></iframe>
            </div>
        );

        const padMap = (
            <div id="pad-map">
                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m26!1m12!1m3!1d768.1733377885727!2d-106.07430309325102!3d39.63410785847996!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m11!3e2!4m5!1s0x876a5c864abb083d%3A0xf5914f5797d2b329!2sSilverthorne%20Pavilion%2C%20Blue%20River%20Parkway%2C%20Silverthorne%2C%20CO!3m2!1d39.6328722!2d-106.0736944!4m3!3m2!1d39.6342314!2d-106.073754!5e0!3m2!1sen!2sus!4v1687708035950!5m2!1sen!2sus"
                    loading="eager"
                    title="pad-map"
                ></iframe>
            </div>
        );

        return (
            <div className="info-block lodging-block">
                <h3 className="section-header">Lodging</h3>
                <h3>Hotels in walking distance</h3>
                <table>
                    <thead>
                        <tr>
                            <td>
                                <a
                                    href="https://thepadlife.com/silverthorne/"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    The Pad
                                </a>
                            </td>
                            <td>
                                <a
                                    href="https://www.ihg.com/hotelindigo/hotels/us/en/silverthorne/densv/hoteldetail"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    Hotel Indigo
                                </a>
                            </td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className="address">
                                491 Rainbow Drive, Silverthorne, CO 80498
                            </td>
                            <td className="address">
                                375 Blue River Parkway, Silverthorne, CO 80498
                            </td>
                        </tr>
                        <tr>
                            <td valign="top">
                                The Pad is a hotel and hostel with private
                                rooms, micro-rooms, and suites. The Pad also
                                offers private and shared dormitory-style rooms.
                                We have a block of rooms with a negotiated rate
                                reserved at The Pad for 09/26 - 09/28.
                            </td>
                            <td valign="top">
                                Hotel Indigo is a traditional hotel with
                                standard rooms and suites.
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <Expander
                                    toggleText="Booking within our block"
                                    contents={
                                        <div>
                                            <p>
                                                We encourage everyone to check
                                                rates both within and without or
                                                block when reserving with The
                                                Pad as rates outside our block
                                                will change based on the time
                                                before your reservation.
                                            </p>
                                            <p className="bold">
                                                Booking online
                                            </p>
                                            Use group code{' '}
                                            <span className="underline">
                                                b652507
                                            </span>{' '}
                                            during the booking process where it
                                            says "have a group code?" on the
                                            right on desktop or at the bottom on
                                            mobile.
                                            <p className="bold">
                                                Booking over the phone
                                            </p>
                                            Tell them you're staying for the
                                            "Culver - Elliott Wedding."
                                        </div>
                                    }
                                />
                            </td>
                            <td></td>
                        </tr>
                        <tr>
                            <td valign="top">{padMap}</td>
                            <td valign="top">{indigoMap}</td>
                        </tr>
                    </tbody>
                </table>
                <h3>Hotels just beyond walking distance</h3>
                <ol>
                    <li>
                        <a href="https://www.hilton.com/en/book/reservation/rooms/?ctyhocn=DENSIHX">
                            Hampton Inn & Suites Silverthorne
                        </a>
                    </li>
                    <li>
                        <a href="https://www.wyndhamhotels.com/laquinta/silverthorne-colorado/la-quinta-silverthorne-summit-co/rooms-rates">
                            La Quinta Inn & Suites by Wyndham Silverthorne
                        </a>
                    </li>
                    <li>
                        <a href="https://dilloninn.com/">Dillon Inn</a>
                    </li>
                    <li>
                        <a href="https://www.bestwestern.com/en_US/book/hotel-rooms.06098.html">
                            Best Western Ptarmigan Lodge
                        </a>
                    </li>
                </ol>
                <h3>Vacation rentals</h3>
                <ol>
                    <li>
                        <a href="https://www.airbnb.com/s/Silverthorne--Colorado--United-States/homes">
                            Airbnb
                        </a>
                    </li>
                    <li>
                        <a href="https://www.vrbo.com/search?adults=2&d1=2024-09-26&d2=2024-09-28&destination=Silverthorne%2C%20Colorado%2C%20United%20States%20of%20America&endDate=2024-09-28&latLong=39.632351%2C-106.074371&regionId=55963&semdtl=&sort=RECOMMENDED&startDate=2024-09-26&theme=&userIntent=">
                            Vrbo
                        </a>
                    </li>
                </ol>
            </div>
        );
    }

    private getTransport(): ReactNode {
        const toVenueMap = (
            <div id="den-silverthorne-map">
                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m28!1m12!1m3!1d785833.8355777765!2d-106.03244959352939!3d39.70227103688718!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m13!3e0!4m5!1s0x876c7f2a98ff44ff%3A0x49583bb435b59c6a!2sDenver%20International%20Airport%2C%20Pe%C3%B1a%20Boulevard%2C%20Denver%2C%20CO!3m2!1d39.8596325!2d-104.67613879999999!4m5!1s0x876a5c864abb083d%3A0xf5914f5797d2b329!2sSilverthorne%20Pavilion%2C%20Blue%20River%20Parkway%2C%20Silverthorne%2C%20CO!3m2!1d39.6328722!2d-106.0736944!5e0!3m2!1sen!2sus!4v1687915684897!5m2!1sen!2sus"
                    loading="eager"
                    title="den-to-silverthorne"
                ></iframe>
            </div>
        );

        return (
            <div className="info-block">
                <h3 className="section-header">Transportation</h3>
                <h3>Air travel</h3>
                <ul>
                    <li>
                        <h4>
                            <a href="https://www.flydenver.com/">
                                Denver International Airport
                            </a>
                        </h4>
                        <p>
                            The nearest airport is Denver International Airport
                            (DEN).
                        </p>
                        {toVenueMap}
                    </li>
                </ul>
                <h3>Ground travel</h3>
                <table>
                    <thead>
                        <tr>
                            <td>Car rentals</td>
                            <td>Mountain shuttles</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td valign="top">
                                Like any airport, DEN has many car rental
                                service options. From the airport, it's a
                                straight drive along I-70 through Denver and
                                into the mountains until you reach Silverthorne.
                            </td>
                            <td valign="top">
                                There are multiple shuttle services that drive
                                from Denver International Airport to
                                Silverthorne. Most shuttles will drive directly
                                to your hotel.
                            </td>
                        </tr>
                        <tr>
                            <td valign="top">
                                <a
                                    href="https://www.flydenver.com/parking_transit/car-rentals"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    More on car rental services at DEN
                                </a>
                            </td>
                            <td valign="top">
                                <a
                                    href="https://www.flydenver.com/parking_transit/transit/mountain-carriers"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    More on mountain shuttle services at DEN
                                </a>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    }

    private getSilverthorne(): ReactNode {
        return (
            <div className="info-block">
                <h3 className="section-header">Things to do in Silverthorne</h3>
                <div>
                    <h3>Walking distance</h3>
                    <ul>
                        <li>
                            <h4>
                                <a href="https://goo.gl/maps/j4k5hheseQezrRHE8">
                                    Bluebird Market
                                </a>
                            </h4>
                            <p>Marketplace with food, coffee, beer, and more</p>
                        </li>
                        <li>
                            <h4>
                                <a href="https://goo.gl/maps/vxW6tzd76aJCKQ8k8">
                                    Silverthorne Bikepath / Blue River Trail
                                </a>
                            </h4>
                            <p>Footpath running along the Blue River</p>
                        </li>
                        <li>
                            <h4>
                                <a href="https://maps.app.goo.gl/1sNyUi3zEnXBugP66">
                                    Outlets at Silverthorne
                                </a>
                            </h4>
                            <p>
                                Collection of outlet shops straddling the Blue
                                River Parkway
                            </p>
                        </li>
                        <li>
                            <h4>
                                <a href="https://goo.gl/maps/DqdYwiyJyWBj1xY6A">
                                    Angry James Brewery
                                </a>
                            </h4>
                            <p>Craft brewery with small bites</p>
                        </li>
                        <li>
                            <h4>
                                <a href="https://sauceontheblue.com/">
                                    Sauce on the Blue
                                </a>
                            </h4>
                            <p>
                                Italian restaurant nestled next to the Blue
                                River.
                            </p>
                        </li>
                        <li>
                            <h4>
                                <a href="https://www.mtnlyoncafe.com/">
                                    Mount Lyon Cafe
                                </a>
                            </h4>
                            <p>Classic diner with breakfast and lunch fares.</p>
                        </li>
                    </ul>
                </div>
                <div>
                    <h3>Driving distance</h3>
                    <ul>
                        <li>
                            <h4>
                                <a href="https://goo.gl/maps/pNU2pb4p6e15zWwRA">
                                    Dillon Amphitheater
                                </a>
                            </h4>
                            <p>
                                Outdoor concert venue overlooking the Dillon
                                Reservoir
                            </p>
                        </li>
                        <li>
                            <h4>
                                <a href="https://goo.gl/maps/eq9C49ChJhSdx1LF7">
                                    Marina Park
                                </a>
                            </h4>
                            <p>Park along the shore of the Dillon Reservoir</p>
                        </li>
                        <li>
                            <h4>
                                <a href="https://goo.gl/maps/Bc6qReqnZ4qYD7iu8">
                                    Syndicate Brewery
                                </a>
                            </h4>
                            <p>Craft brewery specializing in nano-brewing</p>
                        </li>
                        <li>
                            <h4>
                                <a href="https://goo.gl/maps/f29v2RbWAj7CizPe9">
                                    Dillon Dam Brewery
                                </a>
                            </h4>
                            <p>Craft brewery with small bites</p>
                        </li>
                        <li>
                            <h4>
                                <a href="https://www.alltrails.com/trail/us/colorado/lily-pad-lake-trail">
                                    Lily Pad Lake Trail
                                </a>
                            </h4>
                            <p>Moderate difficulty out-and-back trail</p>
                        </li>
                        <li>
                            <h4>
                                <a href="https://goo.gl/maps/fVH8fMPm8UudBEc5A">
                                    Sapphire Point Overlook
                                </a>
                            </h4>
                            <p>Scenic overlook over the Dillon Reservoir</p>
                        </li>
                    </ul>
                </div>
            </div>
        );
    }

    render(): ReactNode {
        return (
            <div>
                <BannerMenu />
                <div className="travel-page">
                    <div>
                        {this.getTransport()}
                        {this.getLodging()}
                        {this.getSilverthorne()}
                    </div>
                </div>
            </div>
        );
    }
}

export default Travel;
