import { BannerMenu } from '../../components/BannerMenu';

export const Event = () => {
    return (
        <div>
            <BannerMenu />
            <div id="event-page">
                {getVenue()}
                {getItinerary()}
            </div>
        </div>
    );
};

const getItinerary = () => {
    return (
        <div>
            <h3 className="section-header">Itinerary</h3>
            <p>Please arrive at 3pm MDT on September 27, 2024.</p>
            <p>We'll have more details for timeline here soon!</p>
        </div>
    );
};

const getVenue = () => {
    return (
        <div className="info-block">
            <h3 className="section-header">Silverthorne Pavilion</h3>
            <div>
                <p>Our venue is the Silverthorne Pavilion.</p>
                <iframe
                    src="https://www.google.com/maps/d/embed?mid=1zbDIBiKPaxbMQMvQxf4ZhFG9iOAXcHQ&ehbc=2E312F"
                    width="640"
                    height="480"
                ></iframe>
            </div>
            <div>
                <h4>Venue Entrance</h4>
                <p>
                    The pavilion's entrace is facing the footbridge over the
                    Blue River.
                </p>
                <h4>Parking</h4>
                <p>
                    There is plenty of parking available across the footbridge
                    over the Blue River. Overnight parking is not allowed in
                    these lots. If you need overnight parking, please let us
                    know ahead of time.
                </p>
            </div>
        </div>
    );
};
