import RedeemIcon from '@mui/icons-material/Redeem';
import { Component, ReactNode } from 'react';
import { BannerMenu } from '../../components/BannerMenu';

export class Registry extends Component {
    render(): ReactNode {
        return (
            <div>
                <BannerMenu />
                <div id="registry-page">
                    <div className="info-block">
                        <a
                            target="_blank"
                            href="https://withjoy.com/ethan-and-erin/registry"
                        >
                            <h3>
                                {
                                    <RedeemIcon
                                        style={{
                                            verticalAlign: 'bottom',
                                            marginRight: '.5rem',
                                        }}
                                    />
                                }{' '}
                                Erin and Ethan on WithJoy{' '}
                                {
                                    <RedeemIcon
                                        style={{
                                            verticalAlign: 'bottom',
                                            marginLeft: '.5rem',
                                        }}
                                    />
                                }
                            </h3>
                        </a>
                        <p>
                            Please feel no obligation to send us a gift! We
                            understand that most of you are traveling from out
                            of town and taking time away from your usual
                            activities.
                        </p>
                        <p>
                            We appreciate this more than we can describe on a
                            website.
                        </p>
                    </div>
                </div>
            </div>
        );
    }
}
