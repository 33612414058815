import { InvokeCommand, LambdaClient } from '@aws-sdk/client-lambda';
import { getLambdaClient } from './awsCommon';

export async function callContactUs(event: any) {
    return await invokeFunction(event);
}

async function invokeFunction(payload: any) {
    const client = getLambdaClient();

    const command = new InvokeCommand({
        FunctionName: 'ContactUs',
        Payload: JSON.stringify(payload),
    });

    try {
        const { Payload } = await client.send(command);

        if (Payload) {
            return true;
        }
    } catch (err) {
        console.log(err);
    }

    return false;
}
