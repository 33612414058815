export const LoadingSpinner = (props: {
    hidden: boolean;
    id: string;
}): JSX.Element => {
    return (
        <div
            id={props.id}
            className={
                props.hidden ? 'loading-spinner hidden' : 'loading-spinner'
            }
        >
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    );
};
